import React from 'react';
import styled from "styled-components";
// import backgroundPc from '../../pages/img/background-pc.jpg'
import { LinkButton } from '../../atoms/button/LinkButton';

export const HomeHeader = () => {

  return (
    <>
      <SContainer>
      <SContent>Shooting a trigger of a memory</SContent>
        {/* <SContent>記録に残す思い出と記憶に残る思い出を</SContent> */}
        {/* <SComment>*各店舗の需要予測と利益の最大化を目的とした施策</SComment> */}
        {/* <LinkButton url="/skill">SKILL</LinkButton> */}
      </SContainer>
    </>
  );
}


// HEADER
const SContainer = styled.div`
  font-family: 'Sawarabi Mincho', serif;
  text-align: center;
  height: 110vh;
  min-height: 500px;
  background-size:  cover;
  background-repeat: no-repeat;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`
const Stitle = styled.h1`
  font-weight: 400;
  font-size: 34px;
  letter-spacing: .08em;
  color: #fff;
`
const SComment = styled.p`
  margin: 0 auto;
  width: fit-content;
  max-width: 500px;
  font-size: 10px;
  letter-spacing: .05em;
  line-height: 24px;
`

const SContent = styled.p`
  margin: -120px auto 0 auto;
  line-height: 24px;
  color: #fff;
  width: 90%;
  max-width: 500px;
`