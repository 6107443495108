import React from 'react';
import styled from "styled-components";

export const Contact = () => {


  return (
    <SContainer>
      <STitle>Contact</STitle>
      <SCommentContainer>
        <SComment>ご依頼、お見積もり、ご相談等、些細なことでも何かありましたら以下のメールアドレスまたは電話番号までご連絡ください。</SComment>
        <SComment>kandacloud331 [at] gmail.com</SComment>
        <SComment>070-8948-3363</SComment>
      </SCommentContainer>
    </SContainer>
  );
}

const SContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0 0 0;
  font-family: 'Sawarabi Mincho', serif;
`
const STitle = styled.h2`
  text-align: center;
  letter-spacing: .1em;
`
const SCommentContainer = styled.div`
  margin: 0 auto;
  width: 70%;
  padding: 0 0 60px 0;
`

const SComment = styled.p`
  padding: 24px 0 0 0;
  text-align: center;
  letter-spacing: .1em;
`
