import React from 'react';
import styled from 'styled-components';
import media from "styled-media-query";
import { SLi, SUl } from './IconCss';

export const IconFotter = () => {
  return (
    <>
      <SIcons>
      <SIconLink><a href='https://www.youtube.com/@tomistaphoto'target='_blank' rel="noreferrer"><i className="fa-brands fa-youtube" style={{color: "#fff",}}></i></a></SIconLink>
      <SIconLink><a href='https://www.instagram.com/tomista_photographer/'target='_blank' rel="noreferrer"><i className="fa-brands fa-instagram" style={{color: "#fff",}}></i></a></SIconLink>
      <SIconLink><a href='https://twitter.com/tomista_photo'target='_blank' rel="noreferrer"><i className="fa-brands fa-x-twitter" style={{color: "#fff",}}></i></a></SIconLink>
      </SIcons>
    </>
  );
}

const SIcons = styled(SUl)`
  ${media.lessThan("medium")`
    /* screen width is less than 768px (medium) */
    margin: 10px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
  `}
`
const SIconLink = styled(SLi)`

  margin: 0 10px;
`