import React from 'react';
import styled from "styled-components";
import media from "styled-media-query";
import { AboutCard } from "../organisms/about/AboutCard";

const step = [
  {
    title: '東京都に生まれる。',
    startDate: '1996',
    content:``,
  },
  {
    title: '写真に魅了される。',
    startDate: '2014',
    content:`人々の思い出におけて、写真の存在が重要であることに心づき、綺麗な写真を撮りたいと思うようになる。`,
  },
  {
    title: '色彩コーディネータを取得する。',
    startDate: '2016',
    content:`より美しい写真を取るために知識を身に着け、本格的に写真を撮り始める。`,
  },
  {
    title: 'バイクで日本を一周する。',
    startDate: '2018',
    content:`日本各所の美しい風景を撮りまわる。`,
  },
  {
    title: 'フォトグラファーとして活動を開始する。',
    startDate: '2019',
    content:`自然体なポートレートとWEBメディア、飲食店の撮影を中心に、プロフィール画像、ライフスタイル、旅、アウトドア、SNSコンテンツ、商品、イベント撮影などの写真活動を行っている。`,
  },
]

export const About = () => {
  return (
    <>
      <STitle>About</STitle>
      <SContainer>
        <AboutCard step={step}></AboutCard>
      </SContainer>
    </>
  );
}

const SContainer = styled.div`
  max-width: 1170px;
  padding: 0 0 0 32px;
  margin: 32px 0 64px;
  position: relative;
  font-family: 'Sawarabi Mincho', serif;
  ::before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 105%;
    background: #522E0B;
    position: absolute;
    left: 30px;
  }

  ${media.greaterThan("large")`
    margin: 64px auto;
    padding: 0 32px;
    ::before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  `}
`
const STitle = styled.h2`
  margin: 0 0 0 70px;
  font-size: 24px;
  letter-spacing: .08em;
  color: #fff;
  font-family: 'Sawarabi Mincho', serif;
  ${media.greaterThan("large")`
    width: 100%;
    margin: 0;
    text-align: center;
  `}
`