import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { LinkButton } from '../atoms/button/LinkButton';
// import { ModalImage } from "../organisms/works/ModalImg";
import portrait_1 from './img/portrait (1).jpg'
import portrait_2 from './img/portrait (2).jpg'
import restaurant_1 from './img/restaurant (1).jpg'
import restaurant_2 from './img/restaurant (2).jpg'
import still_1 from './img/still (1).jpg'
import still_2 from './img/still (2).jpg'
import interview_1 from './img/interview (1).jpg'
import interview_2 from './img/interview (2).jpg'

const works = [
  {
    url: "#",
    title: "出張撮影",
    comment: "プロフィール・前撮り・記念日・旅行等",
    background: portrait_1,
  },
  {
    title: "",
    comment: "",
    background: portrait_2,
  },
  {
    title: "飲食店撮影",
    comment: "料理・内装外観等",
    background: restaurant_1,
  },
  {
    title: "",
    comment: "",
    background: restaurant_2,
  },
  {
    title: "SNS・Webサイトなどその他",
    comment: "ご要望に合わせて",
    background: still_1,
  },
  {
    title: "",
    comment: "",
    background: still_2,
  },
  {
    title: "動画企画・撮影",
    comment: "ショート動画・PR動画・インタビュー動画等",
    background: interview_1,
  },
  {
    title: "",
    comment: "",
    background: interview_2,
  },
]

export const Works = () => {

  // const [showImage, setShowImage] = useState(false);
  // const [worksIndex, setWorksIndex] = useState(0);
  // const [workType, setWorkType] = useState();

  return (
    <>
      <SContainer>
      <STitle>Works</STitle>
        <SWorks>
          {
            React.Children.toArray(works.map((g, i) => {
              return (
                <>
                  {/* <SWork  src={g.background} onClick={() => { setShowImage(true); setWorkType(g.workType) }}> */}
                  <SWork>
                    <SWorkTitleContainer>
                      <SWorkTitle>{g.title}</SWorkTitle>
                    </SWorkTitleContainer>
                    <SCommentContainer>
                      <SComment>{g.comment}</SComment>
                    </SCommentContainer>
                    <SImg src={g.background} />
                    {/* <SMaskContainer>
                      <SMakingComment>
                        <LinkButton url={g.url}>VIEW MORE</LinkButton>
                      </SMakingComment>
                    </SMaskContainer> */}
                  </SWork >
                  {/* <ModalImage showImage={showImage} setShowImage={setShowImage} workType={workType} setWorkType={setWorkType}  worksIndex={worksIndex} setWorksIndex={setWorksIndex}></ModalImage> */}
                </>
              );
            }))
          }

        </SWorks>
      </SContainer>
    </>
  );
}

const SContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0 0 0;
  font-family: 'Sawarabi Mincho', serif;
`
const STitle = styled.h2`
  text-align: center;
  letter-spacing: .1em;
`
const SWorks = styled.div`
  margin: 20px 0 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
  justify-content: center;
`
const SWork = styled.div`
  margin: 0 20px 70px;
  max-width: 400px;
  position:		relative;
`
const SWorkTitleContainer = styled.div`
  height: 48px;
`
const SWorkTitle = styled.h3`
  letter-spacing: .1em;
  margin: 12px 0 0 12px;
  white-space: pre-wrap;
`
const SCommentContainer = styled.div`
  height: 60px;
`
const SComment = styled.div`
  font-size: 12px;
  letter-spacing: .1em;
  margin: 12px 0 0 12px;
`
const SImg = styled.img`
  width: 100%;
  transition: .2s;
  &:hover {
    cursor: pointer;
  }
`
const SMaskContainer = styled(Link)`
  width: 100%;
  aspect-ratio: 1/1;
  position: absolute;
  top: 168px;
  left: 0;
  background-color:	rgba(0,0,0,0.3);
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
    

  ${media.greaterThan("large")`
    width: 100%;
    aspect-ratio: 1/1;
    position: absolute;
    top: 168px;
    left: 0;
    opacity: 0;
    background-color:	rgba(0,0,0,0.3);
    color: #fff;
    text-decoration: none;

    display: flex;
    align-items: flex-end;

    &:hover {
      opacity: .3;
    }
  `}
`
const SMakingComment = styled.div`
  width: 100%;
  height: 100%;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
`
const SMaskingPDF = styled.div`
width: 100%;
text-align: center;
`

